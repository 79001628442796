/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var flag_focus = false; 
  //Function to validate email.
  function validateEmail($email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test( $email );
  }

  //Function to short the text option of the dropdown.
  function validateDropdownFaculty($dropdown) {
    var maxLength = 23;
    var value = $($dropdown).val();
    if (value.length > maxLength) {
      if(value === 'Stakeholder and Community Engagement'){
        maxLength = 20;
      }
      value = value.substr(0, maxLength) + '...';
    }
    if(value!=='all_staff' && value!=='all_faculty') {
      $( "#faculty-tag-dropdown option:selected" ).text(value);
    }
  }

  function addAttrAriaDrop($element, $class, $expanded){
    var $flag = false;
    if($($element).hasClass($class)) {
      $flag = !$flag;
    }
    $($element).attr("aria-selected",$flag);
    if($expanded) {
      $($element).attr("aria-expanded",$flag);
    }
    var parent = $($element).parent();

    if($(parent).hasClass("grandchild-sidebar-menu")){
      var grandParent = $(parent).parent();
      $(grandParent).attr("aria-expanded",true);
    }

  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          var intervalId;

          function startInterval() {
            intervalId = setInterval(function() {
            $('#home-hero-slideshow > div:first')
            .fadeOut(2000)
            .next()
            .fadeIn(2000)
            .end()
            .appendTo('#home-hero-slideshow');
            }, 5000);
          }

          function stopInterval() {
            clearInterval(intervalId);
          }

          //we have to put these little lines between nav items in actively because we sometimes have to take them away,
          //or some items start without them on some page load situations
          //$('#menu-primary-navigation li').addClass('has-bar-on-right');

          //bring down the search bar
          $('.search-dropdown').click(function(){
            $('#search-bar').slideToggle(100);
            return false;
          });

          $('.search-dropdown').keydown(function(e){
            if(e.which === 13) {
              $('#search-bar').slideToggle(100);
              return false;
            }
          });

          //bring down the mobile search bar
          $('.mobile .fa-search').click(function(){
            $('#search-bar-mobile').slideToggle(100);
          });

          $(document).click(function(){
              $("#search-bar, #search-bar-mobile").slideUp(100);
          });

          $("#search-bar, #search-bar-mobile").keydown(function(e){
              e.stopPropagation();
          });

          $("#search-bar, #search-bar-mobile").click(function(){
              e.stopPropagation();
          });

          //make it not go back up when we click in it
          $('.search-field').click(function(e){
            e.stopPropagation();
            e.preventDefault();
          });

          //meganav, stop propogation when clicking in meganav
          $('.mega-nav').click(function(e){
            e.stopPropagation();
          });

          //the stickyness of the nav
          $('#tufts-navbar').affix({
              offset: {
                top: 100,
                bottom: function() {
                  return (this.bottom = $('.footer').outerHeight(true));
                }
              }
          });

          $('#tufts-navbar').on('affix.bs.affix', function(){
            $('#tufts-navbar').fadeTo(10, 0.7);
          });

          $('#tufts-navbar').on('affixed.bs.affix', function(){
            $('#tufts-navbar').fadeTo('slow', 1);
          });

          //Mobile navbar adding accessibility

          $(".navbar-toggle").click(function () {
            $('.sr-only').text(function(i, text){
                return text === "Toggle Navigation. Collapse." ? "Toggle Navigation. Expand." : "Toggle Navigation. Collapse.";
            });
          });

          //the slideshow
          // $('#home-hero-slideshow > div:gt(0)').hide();

          // setInterval(function() {
          //   $('#home-hero-slideshow > div:first')
          //     .fadeOut(2000)
          //     .next()
          //     .fadeIn(2000)
          //     .end()
          //     .appendTo('#home-hero-slideshow');
          // },  5000);

          // function stop(i) {
          //     clearInterval(intervals[i]);
          // }

          $(function() {
            $("#home-hero-slideshow > div:gt(0)").hide();

            startInterval();

            $("#home-hero-slideshow > div .stop").click(function() {
              stopInterval();
              $("#home-hero-slideshow > div .stop").hide();
              $("#home-hero-slideshow > div .start").show();
            });
            $("#home-hero-slideshow > div .start").click(function() {
              startInterval();
              $("#home-hero-slideshow > div .start").hide();
              $("#home-hero-slideshow > div .stop").show();
            });

            $("#home-hero-slideshow > div .stop").keydown(function(e){
              if(e.which === 13) {
                stopInterval();
                $("#home-hero-slideshow > div .stop").hide();
                $("#home-hero-slideshow > div .start").show();
              }
            });
            $("#home-hero-slideshow > div .start").keydown(function(e){
              if(e.which === 13) {
                startInterval();
                $("#home-hero-slideshow > div .start").hide();
                $("#home-hero-slideshow > div .stop").show();
              }
            });

          });

          //getting some mobile check in js so we know whether or not to load meganavs
          var isMobile = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
            },
            any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
            }
          };

          // Accessibilty on Home Page Event/News Tabs

          $('.news-and-events a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            $(e.target).attr('tabindex', '0').attr('aria-selected', 'true');
            $(e.relatedTarget).attr('tabindex', '-1').attr('aria-selected', 'false');
            $( $(e.target).attr('href') ).attr('aria-expanded', 'true').attr('aria-hidden', 'false');
            $( $(e.relatedTarget).attr('href') ).attr('aria-expanded', 'false').attr('aria-hidden', 'true');
          });

          // $('.news-and-events div.tab-pane').on('shown.bs.tab', function (e) {
          //   $(e.target).attr('aria-expanded', 'true').attr('aria-hidden', 'false');
          //   $(e.relatedTarget).attr('aria-expanded', 'false').attr('aria-hidden', 'true');
          // });

          //If mobile, the default menu will load
          if (isMobile.any()) {
            return;
          }

          //If not on mobile block the default behavior so we can have meganavs
          else {
            //seting up mega object for mega menu
            var mega = {
              logMe: function() {
                console.log(this);
              },

              research : {
                link : $('li#menu-item-36 a'),
                li :  $('li#menu-item-36'),
                megacontent : $('#research-mega-nav'),
              },

              education: {
                link : $('li#menu-item-35 a'),
                li :  $('li#menu-item-35'),
                megacontent : $('#education-mega-nav')
              },

              funding : {
                link : $('li#menu-item-34 a'),
                li :  $('li#menu-item-34'),
                megacontent : $('#funding-mega-nav')
              },

              impact: {
                link : $('li#menu-item-33 a'),
                li :  $('li#menu-item-33'),
                megacontent : $('#impact-mega-nav')
              },

              faculty : {
                link : $('li#menu-item-32 a'),
                li :  $('li#menu-item-32'),
                megacontent : $('#faculty-mega-nav')
              },

              about: {
                link : $('li#menu-item-31 a'),
                li :  $('li#menu-item-31'),
                megacontent : $('#about-mega-nav')
              }
            };

            //attach methods to menu objects
            for ( var menu in mega ) {
              var menuObj = mega[menu];
              menuObj.openState = 'closed';
            }
 
            var closeMega = function(menu) {
              menu.megacontent.fadeOut();
              menu.openState = 'closed';
              menu.megacontent.removeClass('mega-is-open');
              menu.li.removeClass('active-mega');
              menu.li.siblings('.current_page_ancestor').removeClass('looks-inactive');
              menu.li.siblings('.current_page_ancestor').addClass('active');
              $(menu.li).find('>a').attr("aria-expanded",false);                            
              flag_focus=false;
            };
 
            var openMega = function(menu) {
                
                // Fucntion to add the esc event in the mega menu.                          
                document.onkeydown = function(evt) {       
                  if(menu.openState ==='open') {  
                      evt = evt || window.event;
                      if (evt.keyCode === 27) {
                        closeMega(menu);
                        var selectorMenu = menu.li.selector+' >a';
                        $(selectorMenu).focus();
                      }
                  }
                };
              menu.megacontent.slideDown();
              menu.openState = 'open';
              menu.megacontent.addClass('mega-is-open');
              menu.li.addClass('active-mega');
              menu.li.siblings('.current_page_ancestor').addClass('looks-inactive');
              menu.li.siblings('.current_page_ancestor').removeClass('active');
              if(flag_focus){
                menu.megacontent.find(".menu-container ul.menu li:first a").focus();
                  if(menu.megacontent.selector === "#faculty-mega-nav") {
                    $('#faculty-mega-nav .blue-button-wrap a').first().focus();
                  }
                flag_focus=false;  
              }
            };
          
            var hideStick = function(menu) {
              //var leftOfMe = $(menu.li).prev('li');
              //leftOfMe.css('&:after', 'width:0px;');
            };

            /*

              apologies for procedural verbose wonkiness

            */

            mega.research.li.click(function(e){
              e.preventDefault();
              e.stopPropagation();
              //mega.research.li.addClass('active-mega');

              //if anyone else is open, close them
              if (mega.education.openState === 'open') { closeMega(mega.education); }
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              if (mega.about.openState === 'open') { closeMega(mega.about); }

              //if this one is already open, close it
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              //or open it if it is closed
              else if (mega.research.openState === 'closed') { openMega(mega.research); }
            });

            mega.education.li.click(function(e){
              e.preventDefault();
              e.stopPropagation();
              //hideStick(mega.education);
              //mega.education.li.addClass('active-mega');

              //if anyone else is open, close them
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              if (mega.about.openState === 'open') { closeMega(mega.about); }

              //if this one is already open, close it
              if (mega.education.openState === 'open') { closeMega(mega.education); }
              //or open it if it is closed
              else if (mega.education.openState === 'closed') { openMega(mega.education); }
            });

            mega.funding.li.click(function(e){
              e.preventDefault();
              e.stopPropagation();
             // mega.funding.li.addClass('active-mega');

              //if anyone else is open, close them
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              if (mega.education.openState === 'open') { closeMega(mega.education); }
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              if (mega.about.openState === 'open') { closeMega(mega.about); }

              //if this one is already open, close it
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              //or open it if it is closed
              else if (mega.funding.openState === 'closed') { openMega(mega.funding); }
            });

            mega.impact.li.click(function(e){
              e.preventDefault();
              e.stopPropagation();
              //mega.impact.li.addClass('active-mega');

              //if anyone else is open, close them
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              if (mega.education.openState === 'open') { closeMega(mega.education); }
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              if (mega.about.openState === 'open') { closeMega(mega.about); }

              //if this one is already open, close it
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              //or open it if it is closed
              else if (mega.impact.openState === 'closed') { openMega(mega.impact); }
            });

            mega.faculty.li.click(function(e){
              e.preventDefault();
              e.stopPropagation();
             // mega.faculty.li.addClass('active-mega');

              //if anyone else is open, close them
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              if (mega.education.openState === 'open') { closeMega(mega.education); }
              if (mega.about.openState === 'open') { closeMega(mega.about); }

              //if this one is already open, close it
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              //or open it if it is closed
              else if (mega.faculty.openState === 'closed') { openMega(mega.faculty); }
            });

            mega.about.li.click(function(e){
              e.preventDefault();
              e.stopPropagation();
             // mega.about.li.addClass('active-mega');

              //if anyone else is open, close them
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              if (mega.education.openState === 'open') { closeMega(mega.education); }

              //if this one is already open, close it
              if (mega.about.openState === 'open') { closeMega(mega.about); }
              //or open it if it is closed
              else if (mega.about.openState === 'closed') { openMega(mega.about); }
            });

            //click anywhere to close any open megas
            $(document).click(function(e){
              if (mega.research.openState === 'open') { closeMega(mega.research); }
              if (mega.funding.openState === 'open') { closeMega(mega.funding); }
              if (mega.impact.openState === 'open') { closeMega(mega.impact); }
              if (mega.faculty.openState === 'open') { closeMega(mega.faculty); }
              if (mega.education.openState === 'open') { closeMega(mega.education); }
              if (mega.about.openState === 'open') { closeMega(mega.about); }
            });

          }
        //end meganav calls
      },

      //active main nav item and the item next to it should not have the lines becasue it looks ugly
      finalize: function() {
        $('#menu-primary-navigation li.current-page-ancestor').prev('.has-bar-on-right').removeClass('has-bar-on-right');
      }
    },
    // Home page
    'page_id_8': {
      init: function() {

      },
      finalize: function() {

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {

      }
    },

    'tax_event_tag': {
      init: function() {
        //toggle the chevrons
        $('.event-toggle-chevron i').click(function(e){
          $(this).parents('.toggle-square').toggleClass('square-open');
          $(this).toggleClass('fa-chevron-up');
          $(this).toggleClass('fa-chevron-down');
        });

        //dynamically match heights of toggling chevrons and accordion rows
        var accordionRows = $('.event-summary');
        accordionRows.each(function(){
          var rowHeight = $(this).height();
          var toggleSquare = $(this).children('.toggle-square');
          toggleSquare.height( rowHeight );
          //toggleSquare.css('padding-top', rowHeight - 54 );
        });
      }
    },

    'tax_event_month': {
      init: function() {
        $('.event-toggle-chevron i').click(function(e){
          $(this).parents('.toggle-square').toggleClass('square-open');
          $(this).toggleClass('fa-chevron-up');
          $(this).toggleClass('fa-chevron-down');
        });

        //dynamically match heights of toggling chevrons and accordion rows
        var accordionRows = $('.event-summary');
        accordionRows.each(function(){
          var rowHeight = $(this).height();
          var toggleSquare = $(this).children('.toggle-square');
          toggleSquare.height( rowHeight );
          //toggleSquare.css('padding-top', rowHeight - 54 );
        });
      }
    },

    'our_partners': {
      init: function() {
        console.log('yup');
        console.log($('.ctsi_circle').attr());
      },

      finalize: function(){
        console.log($('.ctsi_circle').attr());
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $( document ).ready(function() {          
     
     // catch the keys up, down and enter in the menu. 
    $("#menu-primary-navigation li a.dropdown-toggle").keydown(function(e) {
      switch (e.keyCode) {
          case 40:
          case 13:
          case 38: {
            flag_focus=true;
          }          
      }  
    });
    
    //Reset the variable to control the focus with the keys.
    $("#menu-primary-navigation>li").click(function() {        
      flag_focus=false;    
    });
    
    
    //Validate the text options of the dropdown when update the page.
    if($("#faculty-tag-dropdown").length > 0) { 
      validateDropdownFaculty($("#faculty-tag-dropdown"));
    }

    //Update the text name of the options in the select.    
    $("#faculty-tag-dropdown").mousedown(function() {
      $.each($("#faculty-tag-dropdown option"),function(key, value){
        var name= $(value).val();        
        if(name!=='all_staff' && name!=='all_faculty') {          
          $(value).text(name);
        }        
      });      
    });
        
    $("#faculty-tag-dropdown").mouseup(function() {
      validateDropdownFaculty($("#faculty-tag-dropdown"));
    });
        
    //Change the text name in the select if too large.
    $( "#faculty-tag-dropdown" ).change(function() {      
      validateDropdownFaculty(this);
    });
    
    //Set aria-expanded property in the main menu.
    $.each($('#menu-primary-navigation li.dropdown>a'),function(key, value){
      $(value).removeAttr("aria-haspopup");
      $(value).attr("aria-expanded",false);
    });

    //Update the text name of the options in the select.
    $("#menu-primary-navigation li.dropdown>a").click(function() {
        var content_parent = $(this).parent();
        if(!$(content_parent).hasClass('active-mega')) {
          $(this).attr("aria-expanded",true);
        }
    });

    // Event to validate the subscribe form for the users readers.
    $("#mc4wp-form-1 form input[type='submit']").click(function() {
        var element = "#mc4wp-form-1 form input[type='email']";
        var emailaddress = $(element).val();
        if(!validateEmail(emailaddress) || emailaddress === '') {
          $(element).attr('aria-invalid',true);
          var text_error = "<span class=\"screen-reader-text\" id=\"suberrtxt\">You must enter a valid email address</span>";
          if($("#mc4wp-form-1 form .screen-reader-text").length === 0) {
            $(element).after(text_error);
          }
        }
    });

    if($(".parent-sidebar-menu .child-sidebar-menu").length > 0) {
      $(".parent-sidebar-menu .child-sidebar-menu li>a, .parent-sidebar-menu li>a" ).keydown(function(e) {
        switch (e.keyCode) {
          //space
          case 32: {
            var element = $(this).context;
            window.location.href = $(element).attr('href');
            e.stopPropagation();
            return false;
          }
        }
      });

      $.each($(".parent-sidebar-menu .child-sidebar-menu>li"),function(key, value) {
        addAttrAriaDrop(this,'current_page_item',true);
      });
      $.each($(".parent-sidebar-menu .grandchild-sidebar-menu>li, .parent-sidebar-menu>li"),function(key, value) {
        addAttrAriaDrop(this,'current_page_item',false);
      });
    }

    //Function to add class for the buttons in the slideshow
    $("#home-hero-slideshow a.start, #home-hero-slideshow a.stop").hover(function() {
      $(this).find('span.shadow').addClass('active');
    }, function(){
      $(this).find('span.shadow').removeClass('active');
    });

  });

})(jQuery); // Fully reference jQuery after this point.
